import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import redMarker from '../../assets/google-map-markers/red_marker.svg';
import yellowMarker from '../../assets/google-map-markers/yellow_marker.svg';
import purpleMarker from '../../assets/google-map-markers/purple_marker.svg';
import greenMarker from '../../assets/google-map-markers/green_marker.svg';
import blueMarker from '../../assets/google-map-markers/blue_marker.svg';
import greyMarker from '../../assets/google-map-markers/grey_marker.svg';
import transparentMarker from '../../assets/google-map-markers/transparent_marker.svg';
import getInspectionData from '../../services/map';


const containerStyle = {
  marginTop: "-20px",
  height: "calc(100% - 60px)",
  width: "100%",
  position: "absolute",
  overflow: "hidden"
};

const US_CENTERED = {
  lat: 39.8097343,
  lng: -98.5556199
}

const divStyle = {
  background: "white",
  padding: "5px",
  display: "block",
  // padding: "0px",
  lineHeight: "20px",
  maxWidth: "300px"
}


const MapContainer = ({ refreshStatus, setToDefault, setSpinner }) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [backendInspection, setBackendInspection] = useState([]);
  const [webMapInspection, setWebMapInspection] = useState([]);
  const [infoOpen, setInfoOpen] = useState(false);
  const [webMapObj, setWebMapObj] = useState({});
  const [backendObj, setBackendObj] = useState({});

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  })

  const defaultMapOptions = {
    disableDefaultUI: true,
  };

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback((map) => {
    setSpinner(true);
    const data = getInspectionData();
    data.then((result) => {
      if(result !== undefined) {
        setBackendInspection(result.backendRows);
        setWebMapInspection(result.webMapRows);
        setWebMapObj(result.webMapObj);
        setBackendObj(result.backendObj);
      }
      setSpinner(false);
    });

    setMap(map)
  }, [])

  const onUnmount = React.useCallback((map) => {
    setMap(null)
  }, [])


  useEffect(() => {
    if (refreshStatus) {
      const data = getInspectionData();
      data.then((result) => {
        setSpinner(false);
        setBackendInspection(result.backendRows);
        setWebMapInspection(result.webMapRows);
        setWebMapObj(result.webMapObj);
        setBackendObj(result.backendObj);
      });
    }

    setToDefault();
  }, [refreshStatus]);




  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
	return place.length > 8 ? { ...prevState, [place[backendObj['uiid']]]: marker } : { ...prevState, [place[webMapObj['uiid']]]: marker }
    });
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    const timer = setTimeout(() => setInfoOpen(true));
    return () => clearTimeout(timer);
  };

  const getBackendInspectionMarker = (status) => {
    if (status.includes("Confirmed")) {
      return greenMarker;
    } else if (status.includes("Requested")) {
      return yellowMarker;
    } else if (status.includes("On Hold")) {
      return redMarker;
    } else if (status.includes("Outsource")) {
      return purpleMarker;
    } else if (status.includes("Assigned")) {
      return blueMarker;
    } else if (status === "") {
      return greyMarker;
    }
    return transparentMarker;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={US_CENTERED}
      zoom={5}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={defaultMapOptions}
    >

      {
        webMapInspection  && webMapObj ?
          webMapInspection.map(item => {
            return (
              <Marker
                key={item[webMapObj['uiid']]}
                onLoad={(marker) => markerLoadHandler(marker, item)}
                onClick={(event) => markerClickHandler(event, item)}
                position={{ lat: item[webMapObj['Latitude']], lng: item[webMapObj['Longitude']] }}
                icon={{
                  url: purpleMarker
                }}
              />
            )
          }) : null
      }

      {
        backendInspection && backendObj ?
          backendInspection.map(item => {
            return (
              // item[2] !== "" ?
              <Marker
                key={item[backendObj['uiid']]}
                position={{ lat: item[backendObj['Latitude']], lng: item[backendObj['Longitude']] }}
                onLoad={(marker) => markerLoadHandler(marker, item)}
                onClick={(event) => markerClickHandler(event, item)}
                icon={{
                  url: getBackendInspectionMarker(item[backendObj['Coordination Status']])
                }}
              />
              // : ''
            )
          }) : null
      }

      {infoOpen && selectedPlace && (
        <InfoWindow
	  anchor={selectedPlace.length > 8 ? markerMap[selectedPlace[backendObj['uiid']]] : markerMap[selectedPlace[webMapObj['uiid']]]}
          onCloseClick={() => setInfoOpen(false)}
        >
          <div style={divStyle}>
            <p> <b>Project Name: </b> {selectedPlace.length > 8 ? selectedPlace[backendObj['Project Type']] + " - " + selectedPlace[backendObj['Project Name']] + " (" + selectedPlace[backendObj['Project ID']] + ")" : selectedPlace[webMapObj['Project Name']]} </p>
            <p> <b>Address: </b> {selectedPlace.length > 8 ? selectedPlace[backendObj['Address']] : selectedPlace[webMapObj['Address']]} </p>
            <p> <b>Inspector: </b> {selectedPlace.length > 8 ? selectedPlace[backendObj['Inspector']] : selectedPlace[webMapObj['Inspector']]} </p>
            <p> <b>Inspection Date: </b> {selectedPlace.length > 8 ? selectedPlace[backendObj['Inspection Date']] : selectedPlace[webMapObj['Inspection Date']]} </p>
            {selectedPlace.length > 8 ? <p> <b>Coordination Status: </b> {selectedPlace[backendObj['Coordination Status']]} </p> : null}
          </div>
        </InfoWindow>
      )}
      <></>
    </GoogleMap>
  ) : <></>
}

export default React.memo(MapContainer)
