import React from "react";
import "./Login.css";
import GoogleButton from "react-google-button";
import { useGoogleLogin } from "@react-oauth/google";

function Login({ setIsLoggedIn }) {
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      localStorage.setItem("access-token", tokenResponse.access_token);
      setIsLoggedIn(true);
    },
    scope: process.env.REACT_APP_GOOGLE_LOGIN_SCOPES,
    clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  });

  return (
    <div className="login">
      <GoogleButton onClick={googleLogin} />
    </div>
  );
}

export default Login;
