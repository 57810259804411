import './NavbarItem.css';

function NavbarItem(props) {
  return (
    <div className={`navbar-items ${props.showMenu ? " showMenu" : " hideMenu"}`}>
      <ul>
        <li> <a href="https://docs.google.com/spreadsheets/d/1rTYgpA95a1pFXLELe-DksDG4BUyXWfRQ5JEK5TtJacw/edit#gid=0" target="_blank"> Manual Upload </a> </li>
        <li> <a href="https://walkerreid.com/inspections" target="_blank"> Inspection Managment System </a> </li>
        <li> <a href="https://www.myrouteonline.com" target="_blank"> Route Planner </a> </li>
      </ul>
    </div>
  );
}

export default NavbarItem;
