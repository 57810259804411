import './App.css';
import Navbar from './components/navbar/Navbar';
import MapContainer from './components/map-container/MapContainer';
import { useState } from 'react';
import Login from './components/login/Login';
import Logout from './components/logout/Logout';
import React, { useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';



const CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;

function App() {
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem('access-token')) {
      setIsLoggedIn(false);
    }
  });

  const refreshMap = () => {
    setRefresh(true);
  }

  const setToDefault = () => {
    setRefresh(false);
  }

  const setSpinner = (value) => {
    setLoading(value);
  }


  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
    <div className="App">
      {isLoggedIn ? <Logout setIsLoggedIn={setIsLoggedIn} /> : <Login setIsLoggedIn={setIsLoggedIn} />}
      <div className={!isLoggedIn ? 'disableMapView' : {}}>
        <Navbar refreshMap={refreshMap} setSpinner={setSpinner} isLoading={isLoading} />
        {isLoggedIn ? <MapContainer refreshStatus={refresh} setToDefault={setToDefault} setSpinner={setSpinner} /> : ''}
      </div>

    </div>
    </GoogleOAuthProvider>
  );
}

export default App;
