import axios from "axios";

const getInspectionData = async () => {
    try {
        if(localStorage.getItem('access-token')) {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/getInspectionsData`, {
                headers: {
                    token: localStorage.getItem('access-token') //the token is a variable which holds the token
                }
            });
            return response.data;
        }
    } catch (error) {
        if(error && error.response.status === 401 && error.response.data.message === "Invalid Credentials") {
            localStorage.clear();
        }
        return [];
    }

}

export default getInspectionData;
