import './Navbar.css';
import { FiMenu } from "react-icons/fi";
import refreshIcon from '../../assets/images/icon-refresh.svg';
import logoIcon from '../../assets/images/icon-logo.png';
import NavbarItem from '../navbar-items/NavbarItem';
import React, { useState } from "react"
import OutsideClickHandler from 'react-outside-click-handler';
import loadingIcon from '../../assets/images/loading-icon-green.gif';



function Navbar({ refreshMap, setSpinner, isLoading }) {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleToggle = () => {
    setNavbarOpen(state => !state)
  }

  return (
    <header>
      <nav>
        <img src={loadingIcon} className={"loading-icon " + (isLoading ? 'loading-icon-show' : 'loading-icon-hide')} />
        <div className="left-div">
          <FiMenu className="menu-icon" onClick={handleToggle} />
        </div>
        <div className="middle-div">
          <img src={logoIcon} className="icon-logo" alt="icon-logo" />
          <h3> WRS Inspections MapView </h3>
        </div>
        <div className="right-div">
          <img src={refreshIcon} className="icon-refresh" alt="icon-refresh" onClick={() => { setSpinner(true); refreshMap(true); }} />
        </div>
      </nav>
      <OutsideClickHandler onOutsideClick={() => setNavbarOpen(false)} display='inline-block'>
        <NavbarItem showMenu={navbarOpen} />
      </OutsideClickHandler>
    </header>
  );
}

export default Navbar;
