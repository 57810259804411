import React from 'react';
import { googleLogout } from '@react-oauth/google';
import './Logout.css';
import GoogleLogout from './google-logout';


function Logout({ setIsLoggedIn }) {
  const logout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    googleLogout();
  };

  return (
    <div className='logout'>
      <GoogleLogout onLogoutSuccess={logout}></GoogleLogout>
    </div>
  );
}

export default Logout;
